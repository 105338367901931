<template>
  <!-- NAME[epic=财务] 利润报表 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
    >
      <el-form-item label="" prop="year">
        <el-select
          v-model="form.year"
          clearable
          style="width: 140px"
          placeholder="年"
          @change="yearChange"
        >
          <el-option
            v-for="(i, idx) in yearSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="month">
        <el-select
          v-model="form.month"
          clearable
          style="width: 140px"
          placeholder="月"
        >
          <el-option
            v-for="(i, idx) in monthSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="fetchData">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
      <!-- <el-form-item label="" prop="">
        <el-button>导出</el-button>
      </el-form-item> -->
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      default-expand-all
      row-key="id"
      :indent="34"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">序号</template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="科目"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column
        prop="money"
        label="金额"
        width=""
        type=""
        align="center"
      ></el-table-column>
    </el-table>
  </div>
</template>
<script>
  import { getProfitList, monthList, yearList } from '@/api/financial'
  import { downloadFile } from '@/api/Employee'

  export default {
    name: 'LiRun',
    components: {},
    data() {
      return {
        form: {
          year: null,
          month: null,
        },
        yearSelect: [],
        monthSelect: [],
        loading: false,
        list: [],
      }
    },
    computed: {},
    watch: {},
    created() {
      this.initTime()
      this.getYearList()
      this.getMonthList()
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg } = await getProfitList(this.form)
        console.log(data)
        let shou = {
          id: '1',
          name: '一、收入',
          money: data.add_money,
          children: data.add,
        }
        let zhi = {
          id: '2',
          name: '二、支出',
          money: data.less_money,
          children: data.less,
        }
        let li = {
          id: '3',
          money: data.rate,
          children: [],
          name: '三、利润',
        }
        let list = [shou, zhi, li]
        this.list = list
        this.loading = false
      },
      initTime() {
        //  获取当前年
        this.form.year = new Date().getFullYear()
        let month = new Date().getMonth() + 1
        if (month < 10) {
          month = '0' + month
        }
        this.form.month = month
      },
      getYearList() {
        yearList().then((res) => {
          this.yearSelect = res.data
        })
      },
      getMonthList() {
        monthList({ year: this.form.year }).then((res) => {
          this.monthSelect = res.data
        })
      },
      yearChange(val) {
        if (val) {
          this.form.month = ''
          this.getMonthList()
        }
      },
      handleExport() {
        downloadFile(
          '/cashAdmin/subject-detail/export-rate',
          '利润报表.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
